import {InputAdornment, TextField} from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import {Button, Dialog, DialogActions, DialogContent, DialogHeader} from '@therapie-ui';
import NumberInput from '@therapie-ui/Forms/NumberInput';
import PhoneInput from 'react-phone-input-2';
import InfoBar, {InfoBarProps} from '@therapie-ui/InfoBar/InfoBar';
import {SEVERITY} from 'constants/notifications';
import {useCurrency} from 'hooks/currency/useCurrency';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {PaymentTypeModel} from 'types/DerivedApiModel';
import {usePaymentTypes} from '../hooks';
import {IUsePaymentAmountsCalculations} from '../hooks/usePaymentAmountsCalculations';
import {VoucherState} from '../hooks/useVoucherDialogState';
import {PaymentTypeInstance} from '../types';
import formatKlarnaForPayment from '../utils/klarna';
import {klarnaPaymentTypeId}  from '../PaymentTypesSection/PaymentTypesSection';
import config from 'config';
import axios, { AxiosResponse } from 'axios'; 
import useUrlParams from 'hooks/router/useUrlParams';
import {QUERY_KEYS} from 'constants/query';
import { useBusinessContext, useClient } from 'hooks';
import { WALKIN_CLIENT } from 'constants/purchase';
import {getCountryPhoneInfo} from 'utils/phone';
import {BUSINESS_NAMES, BusinessCode} from 'constants/business';
import {FORM_INPUT_COLORS} from 'constants/colors/legacy';
import {Container} from '@material-ui/core';
import { totalToCharge } from '../SummarySection';
import useClinicContext from 'hooks/clinic/useClinicContext';

interface Props extends Pick<IUsePaymentAmountsCalculations, 'amountOfPaymentDueOutstanding'> {
  klarnaAsPaymentType: PaymentTypeModel | undefined;
  voucherDialogState: VoucherState;
} 
var klarnaAmount = 0;

function setKlarnaAmount(amount: number){
  klarnaAmount = amount;
}

const PayWithKlarnaDialog: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({amountOfPaymentDueOutstanding, voucherDialogState}) => {
    const {formatCurrency, currencySymbol} = useCurrency();
    const {instances: paymentTypeInstances, updateInstances} = usePaymentTypes();
  
    const {
      voucherSerialNumber,
      voucherLoading,
      setPayWithKlarnaDialogOpen,
    } = voucherDialogState;

    const params = useUrlParams();
    const {businessId} = useBusinessContext();
    const {clinic, clinicId} = useClinicContext();
    const clientIdFromUrl = params.get(QUERY_KEYS.CLIENT_ID) ?? '';
    const defaultCountryCode = getCountryPhoneInfo(BUSINESS_NAMES[config.BUSINESS_CODE as BusinessCode]).isoCode;
    const {data: client} = useClient({
      businessId,
      clientId: clientIdFromUrl === WALKIN_CLIENT ? undefined : clientIdFromUrl
    });

    const [number, setNumber] = useState("");
    const [name, setName] = useState("");
    const [formError, setFormError] = useState(false);
    const [voucherAmount, setVoucherAmount] = useState(amountOfPaymentDueOutstanding);
    const [klarnaSucceeded, setKlarnaSucceeded] = useState(false);
    const [specialLabelColor, setSpecialLabelColor] = useState<string>(FORM_INPUT_COLORS.DEFAULT);

    const makeKlarnaLinkRequest = (number: string, name: string) => {
      
      // rough validation

      if(name.trim().length == 0 || number.trim().length == 0)
        {
        setFormError(true);
        return; }
      if (name.trim().length > 0 && number.trim().length > 0)
        {
          setFormError(false);
        }
      
      const currency = (config.CURRENCY as string).toLowerCase();
      const phorestRegion = (config.PHOREST_REGION as string).toLowerCase();

      const postData: any = {
          phoneNumber: number,
          email : client?.email,
          unit_amount: (Math.round(voucherAmount * 100)), //has to be in cents for stripe - js needs rounding
          name: (name) + " continue to Pay with Klarna for your thérapie purchase",
          currency: currency,
          quantity: 1,
          clinicName: clinic?.name ?? '',
          clinicId: clinic?.id ?? ''
        };

          axios.post(`${config.KLARNA_SERVICE_URL}` as string, (JSON.stringify(postData)), {headers: {'content-type': 'application/json', 'X-Phorest-Region': phorestRegion}})
          .then((response: AxiosResponse) => {
           console.log('Response:', response.data);
          if (response.status = 201)
          {
            setKlarnaSucceeded(true);
          }})
          .catch((error: any) => {
          alert('Error: Please ensure phone number is valid');
          setKlarnaSucceeded(false);
        },
      );
     }

   const addKlarnaToPurchaseTable = useCallback(
      (voucherAmount?: any, number?:any) => 
    {
        const newVoucher = formatKlarnaForPayment(voucherAmount, number, klarnaPaymentTypeId as string);
        updateInstances(
          paymentTypeInstances
            .filter(instance => instance.id !== newVoucher.paymentTypeId)
            .concat(newVoucher as PaymentTypeInstance)
        );
        setPayWithKlarnaDialogOpen(false);
        setKlarnaAmount(voucherAmount); 
      },
      [voucherAmount]
    );

    useEffect(() => {
      if(client && client.mobile) setNumber(`${client?.mobile}`)
      if(client && client.firstName) setName(`${client?.firstName} ${client?.lastName}`)

    }, [client]);

    useEffect(() => {
      if (formError) setSpecialLabelColor(FORM_INPUT_COLORS.ERROR);
      if (!formError) setSpecialLabelColor(FORM_INPUT_COLORS.DEFAULT);
    }, [formError]);

  
    return (
      <Dialog open={true} transitionDuration={120} fullWidth={true} maxWidth="xs">
        <DialogHeader title="Pay with Klarna" hasBorder />
        <DialogContent>
        
          {!klarnaSucceeded && (
            <InfoBar status={SEVERITY.INFO}>
            Confirm details and click "Send payment link".
            </InfoBar>
          )}

          {klarnaSucceeded && (
            <InfoBar status={SEVERITY.SUCCESS}>
              SMS sent to client. Wait for client to show proof of Klarna payment and then click Accept button.
            </InfoBar>
          )}
          
          <Padding />
          <InlineSegment>

            <KlarnaClientNameInput
              error={formError && name.trim().length == 0}
              disabled={klarnaSucceeded}
              variant="outlined"
              label="Client name"
              name="number"
              autoComplete="off"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <Padding />

            <StyledContainer color={specialLabelColor}>
              <PhoneInput
                disabled={klarnaSucceeded}
                country={defaultCountryCode}
                value={number}
                enableLongNumbers={true}
                onChange={phone => setNumber(phone)}
                containerStyle={inputStyle}
                inputStyle={inputStyle}
                specialLabel={`Phone number`}
                placeholder="+"
                onBlur={() => {
                  if (!formError) setSpecialLabelColor(FORM_INPUT_COLORS.DEFAULT);
                }}
                onFocus={() => {
                  if (!formError) setSpecialLabelColor(FORM_INPUT_COLORS.FOCUS);
                }}
              />
              {formError && number.trim().length == 0 ? <StyledErrorHelper>{formError}</StyledErrorHelper> : null}
            </StyledContainer>
            <Padding />

            <KlarnaAmountInput
              disabled={klarnaSucceeded}
              variant="outlined"
              label="Amount to charge"
              name="amount"
              autoComplete="off"
              value={voucherAmount.toFixed(2)}
              onChange={e => setVoucherAmount(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>
              }}
            />

        </InlineSegment>
        <Padding />

        {(voucherAmount > 0.49) && (
          <InfoBar status={SEVERITY.DEFAULT}>
            Starting at {currencySymbol}{(voucherAmount / 3).toFixed(2)} per month over 3 months, subject to eligibility
            </InfoBar>
        )}

        {(voucherAmount < 0.50) && (
          <InfoBar status={SEVERITY.ERROR}>
            Minimum Klarna amount is 50c / 50p
            </InfoBar>
        )}

       {(voucherAmount > totalToCharge) && (
          <InfoBar status={SEVERITY.ERROR}>
            Klarna amount cannot be more than total amount
            </InfoBar>
        )}

        </DialogContent>

        <DialogActions>
         {(!klarnaSucceeded) && (
          <Button variant="ghost" onClick={() => setPayWithKlarnaDialogOpen(false)}>
            Cancel
          </Button>
         )}

         {(klarnaSucceeded) && (
          <Button variant="ghost" onClick={() => setPayWithKlarnaDialogOpen(false)}>
            Client cancelled
          </Button>
        )}

          {(!klarnaSucceeded && voucherAmount >= 0.50 && !(voucherAmount > totalToCharge)) && (
            <Button
              variant="filled" 
              onClick={() => {makeKlarnaLinkRequest(number, name)}}>
              Send payment link
            </Button>
          )}

          {(!klarnaSucceeded && voucherAmount <0.50) && (
            <Button
              variant="filled" 
              disabled = {true}
              onClick={() => {makeKlarnaLinkRequest(number, name)}}>
              Send payment link
            </Button>
          )}

          {klarnaSucceeded && (
            <Button variant="filled"
            disabled={!klarnaSucceeded} 
            onClick={() => {addKlarnaToPurchaseTable(voucherAmount, number)}}
            >
              Accept payment from client
            </Button>
          )}

         </DialogActions> 
      </Dialog>
    );
  }
);

export {klarnaAmount}
export {setKlarnaAmount}
export default PayWithKlarnaDialog;

const KlarnaClientNameInput = styled(TextField)`
border-radius: 8px
`;

const KlarnaAmountInput = styled(NumberInput)`;
`;

const InlineSegment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .MuiFormControl-root {
    width: 100%;
    margin: 0;
  }
`;

const Padding = styled.div`
  padding-bottom: 20px;
`;

const StyledContainer = styled(Container)`
  width: 100%;
  margin: 0;
  padding: 0;
  .react-tel-input .special-label {
    color: ${props => props.color};
    pointer-events: none;
    font-size: 0.8em;
    left: 8px;
  }
  .react-tel-input .form-control {
    border-radius: 8px;
    transition: none;
    border-color: ${props => (props.color === FORM_INPUT_COLORS.ERROR ? props.color : undefined)};
    :focus {
      border-color: ${props => props.color};
      box-shadow: ${props => 'inset 0 0 0 1px' + props.color};
    }
  }
`;

const StyledErrorHelper = styled.div`
  font-size: 0.75rem;
  pointer-events: none;
  color: ${FORM_INPUT_COLORS.ERROR};
  padding-left: ${({theme}) => theme.spacing(1.75)};
  padding-top: ${({theme}) => theme.spacing(0.5)};
`;

const inputStyle = {
  width: '100%',
  fontFamily: 'inherit',
};
