import * as Sentry from '@sentry/react';
import {PENDING_CARD_FLOW_STATUSES, REQUIRES_CAPTURE_STATUS, SUCCEEDED_STATUS} from 'constants/payments';
import {PURCHASE_ATOM_PREFIX} from 'constants/purchase';
import {selector} from 'recoil';
import {cardPaymentFlowState, paymentTypeInstancesState} from 'state/atoms/purchase';
import {CardPayment, CardPaymentFlow} from 'state/atoms/purchase/types';

export interface ICardPaymentFlowSelector {
  cardPaymentSteps: CardPaymentFlow['steps'];
  currentStep?: CardPayment;
  currentStepIndex: number;
  currentStepIntentRequiresCapture: boolean;
  hasCardPaymentSteps: boolean;
  isTransactionCompleted: boolean;
  isReadyToCreateTransaction: boolean;
  flowState?: CardPaymentFlow['flowState'];
  totalSteps: number;
}

const cardPaymentFlowSelector = selector<ICardPaymentFlowSelector>({
  key: `${PURCHASE_ATOM_PREFIX}CardPaymentFlowSelector`,
  get: ({get}) => {
    const cardPaymentFlow = get(cardPaymentFlowState);
    const cardPaymentSteps = cardPaymentFlow.steps;
    const hasCardPaymentSteps = cardPaymentSteps.length > 0;

    const currentStepIndex = cardPaymentSteps.findIndex(({paymentIntent}) => {
      if (!paymentIntent) {
        const errorMessage = 'paymentIntent is null';
        alert('no payment intent created for payment');
        console.error(errorMessage);
        const paymentInstances = get(paymentTypeInstancesState);
        Sentry.captureException(new Error(errorMessage), {
          extra: {cardPaymentFlow: JSON.stringify(cardPaymentFlow), paymentInstances: JSON.stringify(paymentInstances)}
        });
      }

      return PENDING_CARD_FLOW_STATUSES.includes(paymentIntent?.status);
    });

    const currentStep = cardPaymentSteps[currentStepIndex];

    const currentStepIntentRequiresCapture = currentStep?.paymentIntent?.status === REQUIRES_CAPTURE_STATUS;

    const isReadyToCreateTransaction =
      hasCardPaymentSteps &&
      cardPaymentSteps.every(({paymentIntent}) => paymentIntent?.status === REQUIRES_CAPTURE_STATUS);

    const isTransactionCompleted =
      hasCardPaymentSteps && cardPaymentSteps.every(({paymentIntent}) => paymentIntent?.status === SUCCEEDED_STATUS);

    return {
      cardPaymentSteps,
      currentStep,
      currentStepIndex,
      currentStepIntentRequiresCapture,
      hasCardPaymentSteps,
      isReadyToCreateTransaction,
      isTransactionCompleted,
      flowState: cardPaymentFlow.flowState,
      totalSteps: cardPaymentSteps.length
    };
  }
});

export {cardPaymentFlowSelector};
